import React from 'react';
import Table from '@ssg/common/Components/Table';
import classNames from 'classnames';

export default function RestPeriodsDashboard() {

    const testData = [
        {
            worker: 'John Doe',
            empoyeeId: '1',
            type: 'Skade',
            hours48Over4Months: 'OK',
            daysSinceRestDay: 2,
            elevenHourRest: 'Ja',
            eightHourRest: 'Ja',
            hoursCurrentDay: 8,
            lastWorkTime: '2023-10-01 - 18:00:00',
            availableAgainTime: '',
            currentTask: 'DK010101',
            startedTask: '2023-10-01 - 09:00:00',
        },
        {
            worker: 'Jane Smith',
            empoyeeId: '2',
            type: 'Funk',
            hours48Over4Months: 'OK',
            daysSinceRestDay: 14,
            elevenHourRest: 'Nej',
            eightHourRest: 'Ja',
            hoursCurrentDay: 4,
            lastWorkTime: '2023-10-01 - 12:00:00',
            availableAgainTime: '2023-10-01 - 20:00:00',
            currentTask: '',
            startedTask: '2023-10-01 - 10:00:00',
        },
        {
            worker: 'John Appleseed',
            empoyeeId: '3',
            type: 'Vagt',
            hours48Over4Months: 'Varsel',
            daysSinceRestDay: 11,
            elevenHourRest: 'Ja',
            eightHourRest: 'Ja',
            hoursCurrentDay: 4,
            lastWorkTime: '2023-10-01 - 12:00:00',
            availableAgainTime: '',
            currentTask: 'Intern',
            startedTask: '2023-10-01 - 10:00:00',
        },
    ];

    return (
        <div>
            <Table
                data={testData ?? []}
                keySelector={d => d.empoyeeId}
                noDataFoundText="No data"
                loading={false}
                columns={[
                    {
                        label: 'Medarbejder',
                        selectFn: d => d.worker,
                    },
                    {
                        label: 'Type',
                        selectFn: d => d.type,
                    },
                    {
                        label: '48 timer på 4 mdr.',
                        selectFn: d => d.hours48Over4Months,
                        className: d => classNames({
                            'bg-green': d.hours48Over4Months === 'OK',
                            'bg-yellow': d.hours48Over4Months === 'Varsel',
                            'bg-red': d.hours48Over4Months === 'Overskredet',
                        }),
                    },
                    {
                        label: 'Dage siden fridøgn',
                        selectFn: d => d.daysSinceRestDay,
                        className: d => classNames({
                            'bg-green': d.daysSinceRestDay < 7,
                            'bg-yellow': d.daysSinceRestDay >= 7,
                            'bg-red': d.daysSinceRestDay > 7,
                        }),
                    },
                    {
                        label: '11 timers hvile',
                        selectFn: d => d.elevenHourRest,
                        className: d => classNames({
                            'bg-green': d.elevenHourRest === 'Ja',
                            'bg-red': d.elevenHourRest === 'Nej',
                        }),
                    },
                    {
                        label: '8 timers hvile',
                        selectFn: d => d.eightHourRest,
                        className: d => classNames({
                            'bg-green': d.eightHourRest === 'Ja',
                            'bg-red': d.eightHourRest === 'Nej',
                        }),
                    },
                    {
                        label: 'Timer indeværende døgn',
                        selectFn: d => d.hoursCurrentDay,
                    },
                    {
                        label: 'Tidspunkt for sidste arbejdstid',
                        selectFn: d => d.lastWorkTime,
                    },
                    {
                        label: 'Tidspunkt for tilgængelig igen',
                        selectFn: d => d.availableAgainTime,
                        className: d => classNames({
                            'bg-green': true,
                            'bg-red': false,
                        }),
                    },
                    {
                        label: 'Igangværende opgave',
                        selectFn: d => d.currentTask,
                    },
                    {
                        label: 'Startet opgave',
                        selectFn: d => d.startedTask,
                    },
                ]}
            />
        </div>
    );
}
