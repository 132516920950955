import Datepicker from '@ssg/common/Components/Datepicker';
import Dropdown from '@ssg/common/Components/Dropdown';
import Input from '@ssg/common/Components/Input';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { IEvent } from '../../Schemas/IEvent';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateEventSchema } from '../../Schemas/CreateEventSchema';
import { CalendarEventType, GetUserCalendarsQuery, Permissions } from '@ssg/common/GraphQL/indexV2';
import Button from '@ssg/common/Components/Button';
import TextButton from '@ssg/common/Components/TextButton';
import RestrictedArea from '@ssg/common/Components/RestrictedArea';
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { eventTypes, useCalendarEvents } from './PlannerHelpers';
import { DateTime, Interval } from 'luxon';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';

interface Props {
	user: GetUserCalendarsQuery['userCalendars'][number]['user'];
}
const CreateUserEventComponent: React.FC<Props> = ({ user }): React.ReactElement => {
	const { t } = useTranslation();
	const { postCreateUserCalendarEvent } = useCalendarEvents();

	const [showModal, setShowModal] = React.useState(false);
	const { handleSubmit, register, errors, setValue, control, getValues } = useForm<IEvent>({
		resolver: yupResolver(CreateEventSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IEvent) => {
		// Important only toISO() not UTC for AllDay boolean
		const fromDate = DateTime.fromISO(data.fromDate);
		const toDate = DateTime.fromISO(data.toDate);
		const numberOfDays = Interval.fromDateTimes(fromDate, toDate).length('days');

		const arr = Array.from({ length: numberOfDays + 1 }, (_, i) => fromDate.plus({ days: i }));
		try {
			// arr.forEach(date => {
			// 	postCreateUserCalendarEvent(user.id, {
			// 		title: data.title,
			// 		type: data.type as CalendarEventType,
			// 		timeRange: {
			// 			from: date.toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
			// 			to: date.plus({ days: 1 }).toFormat('yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
			// 		},
			// 		isAllDay: true,
			// 	});
			// });
			await postCreateUserCalendarEvent(user.id, {
				title: data.title,
				type: data.type as CalendarEventType,
				timeRange: {
					from: fromDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
					to: toDate.plus({ days: 1 }).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
				},
				isAllDay: true,
			});

			setShowModal(false);
		} catch (e) {
			console.log(e);
		}
	};

	const fromDateWatch = useWatch({
		control,
		name: 'fromDate',
		defaultValue: formatDateForInput(new Date()),
	});
	return (
		<div className="mt-2">
			<RestrictedArea permissions={[Permissions.PlanningEdit as unknown as Permissions]}>
				<TextButton icon={faCalendarPlus} text="planner.addEvent" onClick={() => setShowModal(true)} textClassName="text-white" iconClassName="text-white text-lg" />
			</RestrictedArea>
			{showModal && (
				<Modal
					visible={showModal}
					close={() => setShowModal(false)}
					title="planner.addEvent"
					size={ModalSize.SMALL}
					body={
						<div className="select-none">
							<form onSubmit={handleSubmit(onSubmit)}>
								<p className="text-blue text-base font-medium">{user.name}</p>
								<p className="text-blue truncate text-base">{user.phone}</p>
								<p className="text-blue truncate text-base">{user.email}</p>

								<Dropdown
									data={eventTypes}
									name="type"
									title="common.category"
									innerRef={register}
									required
									onChange={e => setValue('title', t('planner.types.' + e.currentTarget.value).toString())}
									errorMessage={errors.type?.message}
								/>

								<Input name="title" title="common.title" innerRef={register} required errorMessage={errors.title?.message} defaultValue={t(eventTypes[0].label).toString()} />

								<Datepicker
									name="fromDate"
									title="common.start"
									innerRef={register}
									required
									errorMessage={errors.fromDate?.message}
									onChange={e => {
										const date = e.currentTarget.valueAsDate;
										if (date) {
											const toDate = new Date(getValues('toDate'));
											if (toDate < date) {
												setValue('toDate', formatDateForInput(date));
											}
										}
									}}
									defaultValue={formatDateForInput(new Date())}
								/>

								<Datepicker
									name="toDate"
									title="common.end"
									innerRef={register}
									min={fromDateWatch}
									required
									errorMessage={errors.toDate?.message}
									defaultValue={formatDateForInput(new Date())}
								/>

								<Button submit text="planner.addEvent" success className="mt-3" />
							</form>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default CreateUserEventComponent;
