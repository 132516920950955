import React from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { GetSingleCase_case, UpdateCase, UpdateCaseVariables } from '../../../GraphQL';
import { CaseViewRiskEvaluationSchema } from '../../../Schemas/CaseViewRiskEvaluationSchema';
import { IRiskEvaluationAnswers } from '../../../Schemas/ICaseCreation';
import { Category } from '@ssg/common/Helpers/riskEvaluationHelper';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import Checkbox from '@ssg/common/Components/Checkbox';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import FormFieldHeader from '@ssg/common/Components/FormFieldHeader';
import Input from '@ssg/common/Components/Input';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';

const UPDATE_CASE = loader('src/GraphQL/Cases/UpdateCase.gql');

interface Props {
	open: boolean;
	close: () => void;
	data: Category[];
	caseData: GetSingleCase_case;
}

const RiskEvaluationModal: React.FC<Props> = ({ open, close, data, caseData }): React.ReactElement => {
	const [updateCase, { loading: updateCaseLoading }] = useMutation<UpdateCase, UpdateCaseVariables>(UPDATE_CASE);

	const { handleSubmit, errors, register } = useForm<IRiskEvaluationAnswers>({
		resolver: yupResolver(CaseViewRiskEvaluationSchema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	const onSubmit = async (data: IRiskEvaluationAnswers) => {
		try {
			await updateCase({
				variables: {
					id: caseData.id,
					riskEvaluationAnswers: data.answers.filter(a => a !== null && typeof a !== 'undefined'),
				},
			});
			close();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			title="case.riskEvaluation"
			visible={open}
			close={close}
			size={ModalSize.XLARGE}
			body={
				<form onSubmit={handleSubmit(onSubmit)}>
					<div
						className={classNames('flex flex-wrap', {
							'border-red rounded-default border-2 px-1': errors.answers,
						})}
					>
						{data.map((c, index) => {
							return (
								<div key={index} className="w-1/3">
									<FormFieldHeader title={c.categoryName} />
									{c.questions.map((q, i) => {
										return (
											<div key={i}>
												<Checkbox
													name={`answers[${index + 1}${i}].answer`}
													title={q.question}
													labelWeight={'NONE'}
													popoverInteractive
													labelInfoPopover={
														<div
															dangerouslySetInnerHTML={{
																__html: sanitizeHtml(q.helpText, {
																	allowedAttributes: {
																		'*': ['style', 'href', 'rel', 'target'],
																	},
																}),
															}}
														></div>
													}
													innerRef={register}
													defaultChecked={q.answer}
												/>
												<Input name={`answers[${index + 1}${i}].question`} value={q.questionId} innerRef={register} readOnly className="hidden" />
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
					{errors.answers && (
						<div className="mt-2">
							<FormErrorText text="error.minOneSelected" />
						</div>
					)}
					<Button success submit text="common.save" loading={updateCaseLoading} className="mt-4" />
				</form>
			}
		/>
	);
};

export default RiskEvaluationModal;
